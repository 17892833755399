import HubspotForm from "react-hubspot-form"
import React from "react"
import infographicImg from "../images/CASS.png"

import Layout from "../components/layout"
import Banner from "../components/banner"

const InfographicFormPage = () => {
  return (
    <Layout>
      <section className="infographic-form">
        <Banner
          mediaType="Infographic"
          mediaDuration="5 mins read"
          mediaText="Free Download: Urgent Means Project Survey Insights"
          isFormPage={true}
        />
        <div className="infographic-contents">
          <div className="infographic-description">
            <p className="paragraph-text">Constricted access to social services (CASS) is a very real concern for vulnerable populations. Our infographic highlights key results from a CareAdvisors survey of Chicago-area social workers who share the impact COVID-19 has had on CASS.</p>
            <div className="infographic-img">
              <img src={infographicImg} alt="Infographic" />
            </div>
          </div>
          <HubspotForm
            className="infographic-form"
            portalId="4891903"
            formId="5bd56dda-54f8-4ddb-ba1a-73c6fd198a04"
          />
        </div>
      </section>
    </Layout>
  )
}

export default InfographicFormPage
